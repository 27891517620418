import image1 from '../images/image-01.png';
import image2 from '../images/image-02.png';
import image3 from '../images/image-03.png';
import image4 from '../images/image-04.png';
import image5 from '../images/image-05.png';
import image6 from '../images/image-06.png';
import image7 from '../images/image-07.png';
import image8 from '../images/image-08.png';
import image9 from '../images/image-09.png';
import image010 from '../images/image-010.png';
import image011 from '../images/image-011.png';
import static from '../images/static.png';
import play from '../images/play.png';
import pause from '../images/pause.png';

document.addEventListener('DOMContentLoaded', function() {

    // Function to create and animate static
    function createAndAnimateStatic() {
        const animationContainer = document.createElement('div');
        animationContainer.style = `
        flex: none;
        height: 100vh;
        left: 0;
        opacity: .15;
        pointer-events: none;
        position: fixed;
        top: calc(50.00000000000002% - 100vh / 2);
        width: 100%;
        z-index: 999999;
        `;
        const animationInnerContainer = document.createElement('div');
        animationInnerContainer.style = 'width:100%;height:100%;position:relative;overflow:hidden';
        const staticElement = document.createElement('div');
        staticElement.style = `background: url(${static}); opacity: 0.5; inset: -200%; width: 400%; height: 400%; position: absolute;`;

        function animateTransform() {
        const randomTransformX = Math.random() * 14 - 7;
        const randomTransformY = Math.random() * 14 - 7;
        staticElement.style.transform = `translateX(${randomTransformX}%) translateY(${randomTransformY}%) translateZ(0px)`;
        }

        setInterval(animateTransform, 100);
        animationInnerContainer.appendChild(staticElement);
        animationContainer.appendChild(animationInnerContainer);
        document.body.appendChild(animationContainer);
    }

    createAndAnimateStatic();

    /* START MUSIC */
    const playButton = document.querySelector(".play-music");
    const playButtonMobile = document.querySelector("#play-music-mobile");
    const music = document.getElementById("background-music");
    const musicText = document.getElementById("music-text");
    const musicTextMobile = document.querySelector(".music-text-mobile");
    const musicIcon = playButton.querySelector("img");
    const musicIconMobile = playButtonMobile.querySelector("img");

    let isPlaying = false;

    if (playButton && music && musicText && musicIcon) {
    playButton.addEventListener("click", function() {
        if (isPlaying) {
            music.pause();
            musicText.textContent = "PLAY";
            musicIcon.src = play;
        } else {
            music.play();
            musicText.textContent = "PAUSE";
            musicIcon.src = pause;
        }
        isPlaying = !isPlaying;
    });

    music.addEventListener("ended", function() {
        music.currentTime = 0;
        music.play();
    });
    } else {
        console.error("Play button, music element, music text element, or music icon not found.");
    }

    if (playButtonMobile && music && musicTextMobile && musicIconMobile) {
    playButtonMobile.addEventListener("click", function() {
        if (isPlaying) {
            music.pause();
            musicTextMobile.textContent = "PLAY";
            musicIconMobile.src = play;
        } else {
            music.play();
            musicTextMobile.textContent = "PAUSE";
            musicIconMobile.src = pause;
        }
        isPlaying = !isPlaying;
    });

    music.addEventListener("ended", function() {
        music.currentTime = 0;
        music.play();
    });
    } else {
        console.error("Play button, music element, music text element, or music icon not found.");
    }
    /* END MUSIC */

    const images = [
        { url: image1, mode: 'light' },
        { url: image2, mode: 'light' },
        { url: image3, mode: 'light' },
        { url: image4, mode: 'light' },
        { url: image5, mode: 'light' },
        { url: image6, mode: 'light' },
        { url: image7, mode: 'light' },
        { url: image8, mode: 'light' },
        { url: image9, mode: 'light' },
        { url: image010, mode: 'light' },
        { url: image011, mode: 'light' }
    ];

    let currentIndex = 0;
    let shuffledImages = shuffleArray([...images]); // Initial shuffled array

    const imageContainer = document.getElementById('image-container');
    const slideshowContainer = document.querySelector('.slideshow-container');

    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]]; // Swap elements
        }
        return array;
    }

    function changeImage() {
        const currentImage = shuffledImages[currentIndex];

        // Update image background
        imageContainer.style.backgroundImage = `url(${currentImage.url})`;

        // Update mode class for dark or light images
        slideshowContainer.classList.remove('dark-mode', 'light-mode');
        slideshowContainer.classList.add(`${currentImage.mode}-mode`);

        // Increment index
        currentIndex++;

        // Reshuffle if all images have been shown
        if (currentIndex >= shuffledImages.length) {
            currentIndex = 0;
            shuffledImages = shuffleArray([...images]);
        }
    }

    function startSlideshow() {
        setInterval(() => {
            // Fade out the current image over 300ms
            imageContainer.classList.remove('active');

            // After 300ms, start fading in the new image (remaining 500ms for the total 800ms transition)
            setTimeout(() => {
                changeImage();
                imageContainer.classList.add('active');
            }, 300);  // Start fade-in after 300ms fade-out
        }, 9350); // Adjusted cycle time: 5 seconds display + 800ms transition
    }

    // Initial setup
    changeImage();
    imageContainer.classList.add('active');  // Ensures the first image is visible

    // Start slideshow
    startSlideshow();

    const disclaimerModalTrigger = document.querySelector('.disclaimer-modal-trigger');
    const disclaimerModalTriggerMobile = document.querySelector('#mobile-disclaimer');
    const disclaimerModalClose = document.querySelector('.disclaimer-modal-close');
    const disclaimerModal = document.querySelector('.disclaimer-modal');

    const howModalTrigger = document.querySelector('.how-to-buy-modal-trigger');
    const howModalTriggerMobile = document.querySelector('#business-card-trigger');
    const howModalClose = document.querySelector('.how-to-buy-modal-close');
    const howModal = document.querySelector('.how-to-buy-modal');

    const aboutModalTrigger = document.querySelector('.about-button');
    const aboutModalTriggerMobile = document.querySelector('#mobile-about');
    const aboutModalClose = document.querySelector('.about-modal-close');
    const aboutModal = document.querySelector('.about-modal');

    disclaimerModalTrigger.addEventListener('click', function (event) {
        event.preventDefault();
        disclaimerModal.classList.add('active');
    });

    disclaimerModalClose.addEventListener('click', function (event) {
        event.preventDefault();
        disclaimerModal.classList.remove('active');
    });

    howModalTrigger.addEventListener('click', function (event) {
        event.preventDefault();
        howModal.classList.add('active');
    });

    howModalClose.addEventListener('click', function (event) {
        event.preventDefault();
        howModal.classList.remove('active');
    });

    aboutModalTrigger.addEventListener('click', function (event) {
        event.preventDefault();
        aboutModal.classList.add('active');
    });

    aboutModalClose.addEventListener('click', function (event) {
        event.preventDefault();
        aboutModal.classList.remove('active');
    });

    //

     disclaimerModalTriggerMobile.addEventListener('click', function (event) {
        event.preventDefault();
        disclaimerModal.classList.add('active');
    });

    howModalTriggerMobile.addEventListener('click', function (event) {
        event.preventDefault();
        howModal.classList.add('active');
    });

    aboutModalTriggerMobile.addEventListener('click', function (event) {
        event.preventDefault();
        aboutModal.classList.add('active');
    });

});

console.log("%cI'm thinking Dorsia. Dorsia's nice. Wear something fabulous. 🔪", "font-size: 18px; font-family: 'Helvetica', Arial, sans-serif; color:#ab060d; text-shadow: 2px 2px 4px #000000; font-weight: bold;");


